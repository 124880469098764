<template>
  <div class="index">
    <navigation msg="岗位发布"></navigation>
    <div class="title">
      <div class="left">试试电脑端快速发布</div>
      <div class="right"><van-icon name="arrow" /></div>
    </div>
    <div class="step">
      <div class="step_1">5</div>
      <div class="step_xian"></div>
      <div class="step_2">6</div>
    </div>
    <div class="step_text">
      <div class="step_text_1">生成岗位报价</div>
      <div class="step_text_2">完成岗位发布</div>
    </div>

    <div class="item2">
      <div class="label">本岗位招聘报价总金额</div>
      <div class="name">
        <van-field
          v-model="form.post_price"
          :readonly="poststatus == 'edit'"
          placeholder="请输入本岗位招聘报价总金额"
        />
      </div>
    </div>
    <div class="item1">
      <div class="label"><span>*</span>目标岗位招聘人数（最小值1）</div>
      <div class="name">
        <van-stepper
          :disabled="poststatus == 'edit'"
          @change="price"
          v-model="form.recruit_number"
        />
      </div>
    </div>
    <div class="item1">
      <div class="label"><span>*</span>招聘服务费（单人）（最小值500）</div>
      <div class="name">
        <van-field
          :readonly="poststatus == 'edit'"
          @change="price"
          v-model="form.basics"
          placeholder="请输入招聘服务费（单人）"
        />
      </div>
    </div>
    <!-- <div class="item1">
      <div class="label"><span>*</span>单人悬赏金额</div>
      <div class="name">
        <van-field v-model="form.rewards" placeholder="单人悬赏金额" />
      </div>
    </div> -->
    <div class="xy">
      <van-checkbox
        icon-size="16px"
        checked-color="#ff1f00"
        v-model="need_search_plan"
        >需要委托萝卜猎手创建候选人寻访方案</van-checkbox
      >
    </div>
    <div class="xy">
      <van-checkbox
        icon-size="16px"
        checked-color="#ff1f00"
        v-model="need_competency_model"
        >需要委托创建胜任力模型方案</van-checkbox
      >
    </div>
    <div class="xy">
      <van-checkbox icon-size="16px" checked-color="#ff1f00" v-model="checked1"
        >同意和萝卜猎手签署服务合同</van-checkbox
      >
    </div>
    <div class="xy">
      <van-checkbox icon-size="16px" checked-color="#ff1f00" v-model="checked2"
        >免责条款</van-checkbox
      >
    </div>

    <div style="height: 2rem"></div>
    <div class="footer">
      <div class="btn1" @click="back">上一步</div>
      <div class="btn2" @click="toPage">确认</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      value: "",
      value2: "",
      checked1: true,
      checked2: true,
      need_search_plan: true,
      need_competency_model: true,
      post_id: "",
      form: {
        recruit_number: "1",
        post_price: "", // 悬赏总金额
        basics: "", // 单人悬赏金额
        rewards: "", //招聘服务费
        post_suitable: "", //无需填写
        backgroud_report: "", //无需填写
        need_search_plan: 1,
        need_competency_model: 1, //无需填写
        enterprise_profile: "", //公司简介
        enterprise_culture: "", //企业文化
        enterprise_direction: "", //发展方向
        enterprise_future: "", //发展趋势
        corporate_financing: "", //无融资
        enterprise_property: "", //企业属性
        post_suitable: "",
        backgroud_report: "",
      },
      number: "",
    };
  },
  mounted() {
    if (Object.keys(this.forms).length != 0) {
      Object.keys(this.forms).forEach((key) => {
        this.form[key] = this.forms[key];
      });
    }
    if (this.poststatus == "add") {
      this.$http
        .post("/applet/v1/Freedom/viewDetail", {
          id: localStorage.getItem("conpanyId"),
        })
        .then((res) => {
          res = JSON.parse(res.data);
          this.form.enterprise_profile = res.data.enterprise_introduce;
          this.form.enterprise_culture = res.data.company_culture;
          this.form.enterprise_direction = res.data.company_build;
          this.form.enterprise_future = res.data.product_introduce;
          this.form.corporate_financing = res.data.financing;
          this.form.enterprise_property = res.data.enterprise_property;
          this.number = res.data.number;
          this.calculate();
        });
    }
  },
  computed: {
    // 引入vuex中存储的数据
    ...mapState({
      forms: (state) => state.form,
      poststatus: (state) => state.poststatus,
      postId: (state) => state.postId,
    }),
  },
  methods: {
    calculate() {
      let param = Object.assign({}, this.forms, this.form);
       
      if(param.salary_constitute) {
        param.salary_constitute = param.salary_constitute.join(",");
      }
      
      if (param.work_areas) {
        param.work_areas = param.work_areas.join("/");
      }

      if (param.insurance_fund) {
        param.insurance_fund = JSON.stringify(param.insurance_fund);
      }
      if (param.skills_required) {
        param.skills_required = JSON.stringify(param.skills_required);
      }
      if (param.project_benefits) {
        param.project_benefits = JSON.stringify(param.project_benefits);
      }
      if (param.demand_certificate_name) {
        param.demand_certificate_name = JSON.stringify(
          param.demand_certificate_name
        );
      }
      if (param.job_study_scheme) {
        param.job_study_scheme = JSON.stringify(param.job_study_scheme);
      }
      param.number = this.number;
      param.reqType = "record";
      param.m_id = localStorage.getItem("userId");
      param.company_id = localStorage.getItem("conpanyId");
      this.$http.post("/firm/v1/record/calculateQuote", param).then((res) => {
        res = JSON.parse(res.data);
        this.form.basics = res.data.quote;
        this.price();
      });
    },
    price() {
      this.form.post_price =
        Number(this.form.recruit_number) * Number(this.form.basics);
    },
    jisuan() {
      if (this.form.basics) {
        let analysisReportPrice = 0;
        let backGroundReportPrice = 0;
        if (this.choiceOn) {
          analysisReportPrice = Number(this.form.basics) * 0;
        }
        if (this.form.post_suitable) {
          analysisReportPrice = Number(this.form.basics) * 0.3;
          // console.log(analysisReportPrice);
        }
        // if (this.form.backgroud_report) {
        //   backGroundReportPrice = Number(this.form.basics) * 0.075;
        //   // console.log(backGroundReportPrice);
        // }
        this.form.rewards =
          Number(this.form.basics) -
          (analysisReportPrice + backGroundReportPrice);
      }
    },
    back() {
      this.$router.go(-1);
    },
    async toPay() {
      let param = {
        reqType: "record",
        post_id: this.post_id,
        e_id: localStorage.getItem("conpanyId"),
        m_id: localStorage.getItem("userId"),
        // post_price: ''
      };
      await this.$http.post("/firm/v1/record/jobPay", param).then((res) => {
        res = JSON.parse(res.data);
        if (res.code == 0) {
          this.$toast("支付成功！");
          setTimeout(() => {
            this.$router.push("/step6");
          }, 300);
        }
        if (res.code == 1) {
          this.$dialog
            .confirm({
              title: "提示",
              message:
                "您的余额不足，请完成充值后前往招聘悬赏待发布岗位中支付悬赏金额",
              confirmButtonText: "去充值",
              cancelButtonText: "取消",
            })
            .then(() => {
              this.form = Object.assign({}, this.forms, this.form);
              this.$store.commit("SET_POST", this.form);
              this.$router.push("/recharge");
            })
            .catch(() => {
              // on cancel
            });
        }
      });
    },
    async toPage() {
      if (!this.checked1) {
        this.$toast("请购选萝卜猎手签署服务合同和免责条款");
        return;
      }
      if (!this.checked2) {
        this.$toast("请购选萝卜猎手签署服务合同和免责条款");
        return;
      }
      this.form.need_search_plan = this.need_search_plan ? 1 : 2;
      this.form.need_competency_model = this.need_competency_model ? 1 : 2;
      this.jisuan();

      if(this.poststatus == "add"){
        let param = Object.assign({}, this.forms, this.form);
        param.salary_constitute = param.salary_constitute.join(",");
        if (param.work_areas) {
          param.work_areas = param.work_areas.join("/");
        }
        if (param.insurance_fund) {
          param.insurance_fund = JSON.stringify(param.insurance_fund);
        }
        if (param.skills_required) {
          param.skills_required = JSON.stringify(param.skills_required);
        }
        if (param.project_benefits) {
          param.project_benefits = JSON.stringify(param.project_benefits);
        }
        if (param.demand_certificate_name) {
          param.demand_certificate_name = JSON.stringify(
            param.demand_certificate_name
          );
        }
        if (param.job_study_scheme) {
          param.job_study_scheme = JSON.stringify(param.job_study_scheme);
        }
        param.reqType = "record";
        param.m_id = localStorage.getItem("userId");
        param.company_id = localStorage.getItem("conpanyId");

        // this.$router.push("/step6");
        // /firm/v1/record/PostJobs
        let that = this;
        await this.$http.post("/firm/v1/record/PostJobs", param).then((res) => {
          res = JSON.parse(res.data);
          if (this.poststatus == "add") {
            this.$toast("岗位创建完毕，等待审核");
            setTimeout(() => {
              this.$router.push("/postList");
            }, 500);
          } else if (this.poststatus == "pay") {
            if (res.code == 0) {
              that.post_id = res.data.id;
              that.$dialog
                .confirm({
                  title: "余额支付",
                  message: `使用余额${this.form.post_price}`,
                  confirmButtonText: "确认使用",
                  cancelButtonText: "等一下再来",
                })
                .then(() => {
                  // on confirm
                  that.toPay();
                })
                .catch(() => {
                  // on cancel
                });
            }
          }  
      });
      } else {
        let param = Object.assign({}, this.forms, this.form);
        if (param.insurance_fund) {
          param.insurance_fund = JSON.stringify(param.insurance_fund);
        }
        if (param.post_welfare) {
          param.post_welfare = JSON.stringify(param.post_welfare);
        }
        if (param.project_benefits) {
          param.project_benefits = JSON.stringify(param.project_benefits);
        }
        if (param.demand_certificate_name) {
          param.demand_certificate_name = JSON.stringify(
            param.demand_certificate_name
          );
        }
        if (param.job_study_scheme) {
          param.job_study_scheme = JSON.stringify(param.job_study_scheme);
        }
        param.reqType = "record";
        param.m_id = localStorage.getItem("userId");
        param.company_id = localStorage.getItem("conpanyId");
        param.job_id = this.postId ;
        console.log(param)
        delete param.company
        delete param.address
        delete param.industry
        delete param.number
        delete param.cache_key
        this.$http.post("/firm/v1/record/editJob", param ).then((res) => {
          res = JSON.parse(res.data);
          console.log(res)
          if (res.code == 0) {
            this.$message.success("岗位修改成功！");
            setTimeout(()=>{
              this.$router.push({ path: "/personDetail/myPost"  });
            },500)
            
          }
        });
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.index {
  background-color: #fff;
  height: 100vh;
}
.index /deep/ .van-picker-column__item--selected {
  color: red !important;
}
.index /deep/ .van-picker__confirm {
  color: red !important;
}
.van-cell {
  padding: 10px 0px;
}
.title {
  display: flex;
  justify-content: space-between;
  height: 0.88rem;
  padding: 0 0.28rem;
  line-height: 0.88rem;
  background: #fff0e5;
  .left,
  .right {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ff3b24;
  }
}
.step {
  margin-top: 0.32rem;
  padding: 0 1.12rem;
  display: flex;
  align-items: center;
  .step_1,
  .step_2 {
    width: 0.42rem;
    height: 0.42rem;
    border-radius: 50%;
    background: #ff3b24;
    text-align: center;
    line-height: 0.42rem;
  }
  .step_xian {
    flex: 1;
    height: 8px;
    background: linear-gradient(270deg, #ffc4bd 0%, #ff3b24 100%);
  }
  .step_1 {
    transform: translateX(0.02rem);
    color: #fff;
  }
  .step_2 {
    transform: translateX(-0.02rem);
    color: #fff;
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    background: #ff3b24;
    opacity: 0.3;
  }
}
.step_text {
  margin-bottom: 0.8rem;
  margin-top: 0.21rem;
  display: flex;
  justify-content: space-between;
  padding: 0 0.35rem;
  .step_text_1 {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
  .step_text_2 {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #acb5c8;
  }
}
.index /deep/ input::placeholder {
  font-size: 0.3rem;
}
//输入框
.item1 {
  margin: 0 0.2rem 0.57rem;
  border-bottom: 0.03rem solid #f2f2f2;
  .label {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    span {
      margin-right: 0.1rem;
      color: #ff3b24;
    }
  }
  .name {
    display: flex;
    align-items: center;

    height: 1.12rem;
  }
}
//选择框
.item2 {
  margin: 0 0.2rem 0.2rem;
  border-bottom: 0.03rem solid #f2f2f2;
  .label {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    span {
      margin-right: 0.1rem;
      color: #ff3b24;
    }
    i {
      font-style: none;
      color: #ff3b24;
    }
  }
  .name {
    height: 1.12rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      font-size: 0.3rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #c8c8c8;
    }
  }
  .solt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;
    .btn1 {
      text-align: center;
      line-height: 0.71rem;
      box-sizing: border-box;
      width: 3.32rem;
      height: 0.71rem;
      background: #fff0e5;
      border-radius: 0.03rem;
      border: 0.02rem solid #ff3b24;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ff3b24;
    }
    .btn2 {
      text-align: center;
      line-height: 0.71rem;
      box-sizing: border-box;
      width: 3.32rem;
      height: 0.71rem;
      background: #f1f0ef;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #6d6b6b;
      border-radius: 0.03rem;
    }
  }
}
// 多选择框
.item3 {
  margin: 0 0.2rem 0.57rem;
  border-bottom: 0.03rem solid #f2f2f2;
  .label {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    span {
      margin-right: 0.1rem;
      color: #ff3b24;
    }
    i {
      font-style: none;
      color: #6277da;
    }
  }
  .name {
    height: 1.12rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      flex: 1;
      font-size: 0.3rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      .right {
        color: #c8c8c8;
        margin-right: 0.15rem;
      }
    }
  }
  .solt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;
    .btn1 {
      text-align: center;
      line-height: 0.71rem;
      box-sizing: border-box;
      width: 3.32rem;
      height: 0.71rem;
      background: #fff0e5;
      border-radius: 0.03rem;
      border: 0.02rem solid #ff3b24;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ff3b24;
    }
    .btn2 {
      text-align: center;
      line-height: 0.71rem;
      box-sizing: border-box;
      width: 3.32rem;
      height: 0.71rem;
      background: #f1f0ef;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #6d6b6b;
      border-radius: 0.03rem;
    }
  }
}
.xy {
  margin-left: 0.2rem;
  margin-bottom: 0.2rem;
}
.footer {
  position: fixed;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.29rem;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 1.56rem;
  border-top: 0.02rem solid #f2f2f2;
  background: #ffffff;
  .btn1 {
    text-align: center;
    line-height: 0.95rem;
    width: 2.56rem;
    height: 0.95rem;
    background: #ffffff;
    border-radius: 0.1rem;
    border: 0.03rem solid #c3c3c3;
    font-size: 0.32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #989898;
  }
  .btn2 {
    text-align: center;
    line-height: 0.95rem;
    width: 4.12rem;
    height: 0.95rem;
    background: #ff3b24;
    border-radius: 0.1rem;
    font-size: 0.32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
  }
}
</style>